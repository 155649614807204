import React from 'react';
import Verify from './Verify';
import './App.css';
import ABELogo from "./images/ABE-logo.png";
import AusPostLogo from "./images/AustraliaPost_2019.png";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div id="title-header">
        <div id="abelogo">
          <img src={ABELogo} alt="Australian Bond Exchange"></img>
        </div>
          <h2>Digital ID Verification</h2>
        </div>
        <br />
        <div id="instruct1">
            <p>We need to verify your identity before you’ll be able to trade. You can do this quickly and securely with our electronic ID verification partner, Australia Post.</p>
            <br/>
            <p>To perform this ID Check, your usual identification like <em>Drivers License, Passport and Medicare card</em> can be used.</p>
            <br />
            <p>Complete your Identity Verification using the Australia Post Digital Verification Service. <br />
    By <em>clicking the button below</em>, a new window will open to allow you to verify your identity.</p>
            <br />
        </div>
        <div id="verify" className="ff">
          <Verify/>
        </div>
        <h5>Powered by</h5>
        <div id="austpost" >
          <img src={AusPostLogo} alt="Australia Post Logo"></img>
        </div>
        <br /><br />
        <div id="instruct2">
            <p>
            If you don't want to verify online now  or the verification fails you can take your IDs to your local post office or accountant to confirm your identity and make certified copies of these documents.</p>
            <p>
            <br />
            The IDs can be your passport, drivers license, medicare card or other approved forms of ID. <br />
            (Australian Birth Certificate, Australian Citizen Certificate, Pension Card, Health Care Card)<br />
            <br />
            Please check with your local post office as they sometimes limit the hours.</p>
            <br />
            <p>Alternatively, you can contact us to organise alternative methods to certify your documents.</p>
            <br />
            <p>Send us the signed form and the certified copies to:
            <br /><br />
            Australian Bond Exchange, PO Box R445 Royal Exchange, NSW 1225.
            </p>
        </div>
      </header>
    </div>
  );
}

export default App;
