import React from 'react';
import alertify from 'alertifyjs';
import '../node_modules/alertifyjs/build/css/alertify.css';

export default class Verify extends React.Component {

    render() {
        return (
          <div id="digitalid-verify"></div>
        )
    }

    notifyFailure(msg)
    {
      alertify.warning("Verification was not successful. Please try again.", process.env.REACT_APP_ALERT_DELAY);
      let subject = 'Australia Post Digital Id Verification Failed';
      let text = `Verification Failed. <br> Description: ${msg.error_description}`;
      //this.emailResult(msg.code, msg.transaction_id, subject, text );
    }
  
    notifyExpiration(msg)
    {
      alertify.warning("Verification process has expired. Please try again.", process.env.REACT_APP_ALERT_DELAY);
      let subject = 'Australia Post Digital Id Verification Expired';
      let text = `Verification Expired. <br> Description: ${msg.error_description}`;
      //this.emailResult(msg.code, msg.transaction_id, subject, text );
    }
  
    notifyCancellation(msg)
    {
      alertify.warning("Verification was cancelled. Please try again.", process.env.REACT_APP_ALERT_DELAY);
      let subject = 'Australia Post Digital Id Verification Cancelled';
      let text = `Verification Cancelled. <br> Description: ${msg.error_description}`;
      //this.emailResult(msg.code, msg.transaction_id, subject, text );
    }
  
    notifySuccess(msg) 
    {
      alertify.success(msg, process.env.REACT_APP_ALERT_DELAY);
    }

    RerouteToOrigin(response)
    {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      
      if (response.status === 200)
        this.notifySuccess("ID Verification was successful. ABE Staff will contact you when process is completed")
      else
        this.notifyFailure("There was an issue with the ID Verification. Please contact ABE for further instructions")

      if (urlParams.has('source'))
      {
        const source = urlParams.get('source');

        switch (source)
        {
          case "application-form":
            this.notifySuccess("Returning to Application Form");
            break;
          default:
            break;
        }
      }
    }

    
    ToggleVerifyButton()  {
      var e = document.getElementById('digitalid-verify');
  
      if ( e.style.display !== 'none' ) {
          e.style.display = 'none';
      } else {
          e.style.display = 'block';
      }
    }

    async componentDidMount() {

        const script = document.createElement('script');

        console.log("OAUTH Script: ", process.env.REACT_APP_AUSPOST_SCRIPT);
        console.log("OAUTH Server: ", process.env.REACT_APP_OAUTH_SERVER);
        console.log("React Client ID: ", process.env.REACT_APP_CLIENT_ID)

        // NOTE : If public-ip module becaomes unusable, this could be an alternative solution where the OAuth-Server works out the client IP
        // https://stackoverflow.com/questions/48220736/how-to-get-client-public-ip-from-api-request-in-node-js
        const clientIP = require('public-ip');
        var ipAddr = await clientIP.v4();
        console.log("Client IP: ", ipAddr);
        var timestamp = new Date().toLocaleString();
        console.log("Timestamp: ", timestamp);

        script.src = process.env.REACT_APP_AUSPOST_SCRIPT;
        script.async = true;

        document.body.appendChild(script);
        let form = this;
        script.onload = () => {
            console.log("Client ID: ", process.env.REACT_APP_CLIENT_ID);
            /* Verify with Digital iD */
            window.digitalId.init({
                clientId: process.env.REACT_APP_CLIENT_ID,
                uxMode: 'popup',
                onLoadComplete: function () {
                },
                onComplete: async function (response) {
                    console.log("Object returned" + response);
                    console.log("Code : " + response.code);
                    console.log("Txn ID: " + response.transaction_id);
                    ipAddr = await clientIP.v4();
                    console.log("IP Address to Pass: " + ipAddr);
                    var timestamp = new Date().toLocaleString();
                    console.log("Timestamp to Pass: ", timestamp);

                    fetch(process.env.REACT_APP_OAUTH_SERVER , {
                        method: 'POST',
                        headers: {
                           //Enable Access-Control items for CORS Local Debug only. Disable otherwise
                          // 'Access-Control-Allow-Origin': '*', 
                          // 'Access-Control-Allow-Methods': 'POST',
                          'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                          code: response.code,
                          IP: ipAddr,
                          timestamp : timestamp
                        })
                      })
                      .then(response => {
                        console.log(response);
                        form.RerouteToOrigin(response);
                      });         
                },
                onClick: function (opts) {
                  form.ToggleVerifyButton();
                },
                onKeepAlive: function () {
                }
            });

        }
    }
}
